<template>
<div class="refund-container">
  <v-overlay :value="loading" color="white" :opacity="1" z-index="9999">
    <v-progress-circular indeterminate size="64" color="primary" />
  </v-overlay>
  <img class="close-page" src="/img/icons/close.svg" @click="closePage()">
  <Item>
    <ItemSection side>
      <img src="/img/nick-refund-page.svg" alt="Nick" width="100px">
    </ItemSection>
    <ItemSection class="header-title" v-if="carriers.length">
      <div v-if="selectedCarrier">
        <h5>{{selectedCarrier.name}}</h5>
        <h3>{{pageTitle[0]}}</h3>
        <h1>{{pageTitle[1]}}</h1>
      </div>
      <div v-else>
        <h1>Não conseguimos identificar qual é a sua operadora.</h1>
        <h3>Selecione abaixo:</h3>
      </div>
    </ItemSection>
  </Item>
  <RefundOperatorSelect
    @open="openCarrier"
    :operators="carriers"
    v-if="carriers.length > 1 && !selectedCarrier" />
  <div v-else>
    <div class="number-list" v-if="carriers.length" v-html="selectedCarrier.msg"></div>
    <NumberList v-else v-model="steps" />
    <div class="refund-actions gutter-md" v-if="this.carriers.length">
      <v-btn color="primary" rounded x-large @click="openUrl(selectedCarrier.site)">
        Acessar o site
      </v-btn>
      <v-btn color="primary" outlined rounded x-large @click="openApp()">
        <span class="text-truncate">Baixar o APP {{selectedCarrier.name}}</span>
      </v-btn>
    </div>
    <div v-if="selectedCarrier && selectedCarrier.allowCreateCase" class="refund-footer">
      <h3> Se preferir, você pode <br> solicitar pelo Eu Protegido</h3>
      <v-btn color="primary" rounded x-large @click="openCaseForm()">
        Solicitar {{caseType}}
      </v-btn>
    </div>
  </div>
</div>
</template>
<script>
import Item from '../../components/structure/item/item'
import ItemSection from '../../components/structure/item/itemSection'
import NumberList from '../../components/structure/numberList'
import AccountService from "@/services/contract/AccountService";
import RefundOperatorSelect from './RefundOperatorSelect'
export default {
  name: 'RefundIntro',
  components: {
    Item,
    ItemSection,
    NumberList,
    RefundOperatorSelect
  },
  data () {
    return {
      loading: false,
      carriers: [],
      selectedCarrier: null,
      accountService: null,
      type: null,
      caseType: null,
      pageTitle: [
        'Saiba como solicitar',
        'seu reembolso'
      ]
    }
  },
  async created () {
    this.accountService = new AccountService();
    let { caseType, type } = this.$route.query;
    this.caseType = caseType;
    this.type = type;
    if (type === 'reembolso') {
      this.pageTitle = [
        'Saiba como solicitar',
        'seu reembolso'
      ]
      await this.getCarrier('fetchCarrierReimbursement')
    } else if (type === 'rede_credenciada') {
      this.pageTitle = [
        'Saiba como solicitar',
        'sua rede credenciada'
      ]
      await this.getCarrier('fetchCarrierNetwork')
    } else {
      await this.getCarrier()
    }
  },
  computed: {
    steps () {
      if (this.carriers.length) {
        return [
          `Acesse o site da ${this.selectedCarrier.name}, ou abra o app do beneficiário.`,
          'Acesse a area de reembolso.',
          'Informe o tipo de reembolso.',
          'Informe os dados do médico',
          'Envie os documentos e comprovantes necessários.',
          'Acompanhe o andamento da solicitação.'
        ]
      }
      return null
    }
  },
  watch: {
    selectedCarrier () {
      if (this.selectedCarrier && (!this.selectedCarrier.googlePlay && !this.selectedCarrier.appStore)) {
        this.openCaseForm()
      }
    }
  },
  methods: {
    openCaseForm () {
      this.$router.push({
        name: 'addCase',
        params: {
          optionTitle: this.$route.query.caseType,
          optionOptions: this.$route.query.type
        }
      })
    },
    closePage () {
      if (this.selectedCarrier && this.carriers.length > 1) {
        this.selectedCarrier = null
      } else {
        this.$router.push('/')
      }
    },
    openCarrier (carrier) {
      this.selectedCarrier = carrier
    },
    openUrl (url) {
      window.open(url, '_blank')
    },
    openApp () {
      if(this.$device.ios) {
        this.openUrl(this.selectedCarrier.appStore)
      } else {
        this.openUrl(this.selectedCarrier.googlePlay)
      }
    },
    remapList (list) {
      return list.map((v) => {
        return {
          id: v.id,
          name: v.comercialName,
          type: v.type,
          googlePlay: v.appAddress,
          appStore: v.appAddressIos,
          site: v.website,
          hasAccreditedNetworkApp: v.hasAccreditedNetworkApp,
          hasReimbursementApp: v.hasReimbursementApp,
          msg:  this.type === 'reembolso' ? v.reimbursementMsg :
                this.type === 'rede_credenciada' ? v.accreditedNetworkMsg : null,
          allowCreateCase: this.type == 'reembolso' ? v.allowCreateReimbursementCase :
                           this.type == 'rede_credenciada' ? v.allowCreateAccreditedNetCase : false,
        }
      })
    },
    async getCarrier() {
      this.loading = true
      try {
        const { data } = await this.accountService.findAllCarrierByBeneficiary()
        if (data.length) {
          this.carriers = this.remapList(data)
          if (this.carriers.length === 1) {
            this.selectedCarrier = this.carriers[0]
          } else {
            this.selectedCarrier = null
          }
        }
      } catch (error) {
        //vazio
      }
      this.loading = false
    }
  }
}
</script>
<style>
.number-list ol{
  max-width: 350px;
  counter-reset: my-awesome-counter;
  list-style: none;
  padding-left: 40px;
}
.number-list li{
  margin: 0 0 1rem 1rem;
  counter-increment: my-awesome-counter;
  position: relative;
  min-height: 40px;
}
.number-list li::before {
  content: counter(my-awesome-counter);
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  position: absolute;
  --size: 32px;
  left: calc(-1 * var(--size) - 10px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  top: 0;
  background: #22377e;
  border-radius: 50%;
  text-align: center;
  z-index: 2;
}
.number-list li:not(:last-of-type):after{
  content: '';
  border: 1px solid #22377e;
  position: absolute;
  top: 15px;
  left: -27px;
  height: 60px;
  z-index: 1;
}
.refund-container{
  padding: 20px;
  background: #fff;
  min-height: 100vh;
}
.refund-footer{
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, .1);
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background: #fff;
  padding: 26px 26px 56px 26px;
  margin: -22px;
  margin-top: 22px !important;
  text-align: center;
}
.header-title{
  margin-top: 27px;
  padding: 16px;
}
.header-title h1{
  font-size: 28px;
  color: #4a499b;
  line-height: 1.2;
}
.header-title h3, .refund-footer h3{
  font-size: 18px;
  color: #4a499b;
  line-height: 1;
}
.refund-footer h3{
  margin-bottom: 26px;
  line-height: 1.2;
}
.header-title h5{
  font-size: 14px;
  color: #8e8e9f;
  line-height: 1.5;
}
.dialog-content{
  padding: 32px;
}
.dialog-content .cta.v-btn{
  position: fixed;
  bottom: 30px;
  left: 30px;
  width: calc(100vw - 60px);
  border-radius: 16px;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: .5px;
}
.dialog-title h5{
  color: #8e8e9f;
  font-size: 20px;
  font-weight: 500;
}
.dialog-title{
  padding: 0 16px;
}
.dialog-title h3{
  color: #292867;
  font-size: 22px;
  font-weight: 900;
}
.refund-actions{
  padding: 16px 24px;
}
.refund-container .v-btn--rounded{
  border-radius: 16px;
}
.refund-actions.gutter-md{
  margin: -16px;
}
.refund-actions.gutter-md *{
  margin: 16px;
}
.refund-actions.gutter-md *{
  margin: 8px;
  width: calc(100% - 16px)
}
.full-width{
  width: 100%;
}
.close-page{
  position: absolute;
  width: 24px;
  top: 24px;
  right: 24px;
}
</style>
